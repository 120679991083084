@import "./styles/home.scss";
@import "./styles/about.scss";
@import "./styles/contact.scss";
@import "./styles/work.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --main-text-color: #E48586;
  --pinkish-grey: #fbd6d287;
  --background-color: #fff;
  --reveal-bg-color: rgba(246, 137, 137, 0.7);
}

.main-bg {
  transition: all 1s;

  .top-container {
    transition: all 1s;
  }
}

.about-page {
  transition: all 1s;
}

@media (prefers-color-scheme: dark) {
  [data-theme='dark'] {
    // --background-color: rgb(60, 51, 80);
    --background-color: #060816;
    --main-text-color: #915EFF;
    --reveal-bg-color: #7f6f97;
    --foreground-color: #071952;
    --pinkish-grey: #27374D;
    --secondary-text-color: gray;
    --toggle-bg: rgb(6, 2, 249);
    --toggle-fg: rgb(0, 0, 0);
    --background-color-primary: #190482;
    --background-color-secondary: #aaa6c3;
    --background-color-tertiary: #151030;
    --contact-box-color: #151030;
    --background-color-darker: #090325;
    --background-color-white: #f3f3f3;
    transition: all 0.4s;

    .info-text {
      color: white !important;
    }

    .heading-intro {
      color: white;
    }

    .main-bg {
      transition: all 0.4s;

      .top-container {
        transition: all 1s;
      }
    }

    .bg-big {
      opacity: 0.3;
    }

    .work-card {
      .shadow-card {
        color: white !important;

        .text-pink-950 {
          color: white !important;
        }
      }
    }

    .shadow-card {
      background-color: #754ccd35;
      background-image: none;

      .gradient-text {
        background: -webkit-linear-gradient(rgb(253, 3, 224), rgb(60, 255, 232));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

    }

    .contact-page {
      .contact-form {
        .text-gray-600 {
          color: whitesmoke;
        }

        button {
          background-color: #915effac;
        }
      }

      .dark-input {
        background-color: #754ccd35;
        color: white;
      }

      .dark-input::placeholder {
        color: white;
      }
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 767px) {
    .contact-page {
      .dark-input {
        background-color: var(--background-color);
        color: white;
      }

      .shadow-card {
        background-color: var(--background-color);
      }
    }
  }
}

[data-theme='dark'] {
  --background-color: #060816;
  // --background-color: rgb(60, 51, 80, 0);
  --main-text-color: #915EFF;
  // --main-text-color: #754ccd35;
  --reveal-bg-color: #7f6f97;
  --foreground-color: #071952;
  --pinkish-grey: #27374D;
  --secondary-text-color: gray;
  --toggle-bg: rgb(6, 2, 249);
  --toggle-fg: rgb(0, 0, 0);
  --background-color-primary: #190482;
  --contact-box-shadow: #aaa6c3;
  --background-color-tertiary: #151030;
  --contact-box-color: #151030;
  --background-color-darker: #090325;
  --background-color-white: #f3f3f3;
}